/* eslint-disable react/prop-types */
import { loginUser, resendOtp, verifyOtp } from '@services/session.service';
import { HeadTagData } from '@helpers/headTagData';
import { getMultipleData } from '@services/users.service';
import { Image, Modal, Text } from '@ui/atoms';
import useWindowDimensions from '@ui/hooks/useWindowDimensions';
import NextHeadTag from '@ui/molecules/NextHeadTag';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { theme } from 'tailwind.config';
import useOtpInput from '@ui/hooks/useOtpInput';
import Button from '@ui/atoms/design-system/Button';
import { otpLength, resendOtpErrorWord } from '@ui/helpers/constants';
import { OtpContext } from '@ui/helpers/enums/otp-context';
import { appendCountryCodeAndFormatMobile, maskEmail } from '@helpers/miscelleanous';

const VerifyOtpModal = ({
    show,
    onClose,
    editMobileNumber,
    userInput,
    onSessionCreationSuccess,
    onSessionCreationError,
    otpModes
}) => {
    const { width } = useWindowDimensions();
    const otpExpiresAfter = 60;
    const [seconds, setSeconds] = useState(otpExpiresAfter);
    const [message, setMessage] = useState('');
    const [multipleMessage, setMultipleMessage] = useState('');
    const [otpMessage, setOtpMessage] = useState('OTP sent successfully!');
    const [loading, setLoading] = useState(false);
    const [selectAccountModal, setMultipleAccountModal] = useState(false);
    const [multipleInvestor, setMultipleLoginData] = useState([]);
    const [loginButtonState, setLoginButtonState] = useState(false);
    const [isResendButtonLoading, setIsResendButtonLoading] = useState(false);
    const [otpId, setOtpId] = useState('');
    const [tempAuth, setTempAuth] = useState();
    const timeoutRef = useRef();
    const { otpValue, setOtpValue, OtpInputComponent, setDisabled } = useOtpInput({
        setMessage,
        id: 'mobileNumberConfirmationOtp'
    });

    const mobileNumber = useMemo(() => userInput?.mobile, [userInput?.mobile]);
    const countryCode = useMemo(
        () => userInput?.countryCode,
        [userInput?.countryCode]
    );

    const { handleSubmit } = useForm();

    const editMobile = () => {
        setOtpValue('');
        setMessage('');
        editMobileNumber();
    };

    const onPanSubmit = async () => {
        setLoading(true);
        const response = await loginUser(mobileNumber, [otpId], userInput, tempAuth.isUserMigrated, tempAuth.token);
        if (response.status) {
            await onSessionCreationSuccess({ user: response.entity });
        } else {
            setMessage(response.message);
            if (response.message[0].toLowerCase().includes(resendOtpErrorWord)) {
                setDisabled(true);
                setSeconds(0);
            }
        }
        setLoading(false);
    };

    const onSubmit = async () => {
        setLoading(true);
        let verifyResponse;
        //login
        verifyResponse = await verifyOtp({ mobile: mobileNumber, context: OtpContext.AUTHENTICATION, otp: otpValue, });
        if (verifyResponse.status) {
            setOtpId(verifyResponse.entity.id);
            await createSession(verifyResponse.entity.id);
        }
        else {
            setMessage(verifyResponse.message);
            setOtpMessage('');
            if (verifyResponse.message[0].toLowerCase().includes(resendOtpErrorWord)) {
                setDisabled(true);
                setSeconds(0);
            }
        }
        setLoading(false);
    };

    const createSession = async (otpId) => {
        const response = await loginUser(mobileNumber, [otpId], { ...userInput, countryCode: userInput.countryCodeWithPrefix });
        if (response.status === true) {
            const multipleAccountResponse = await getMultipleData(
                userInput.mobile,
                response.entity
            );
            if (multipleAccountResponse?.entity?.multipleLogin) {
                setTempAuth(response.entity);
                setMultipleAccountModal(true);
                let loginData =
                    multipleAccountResponse.entity.multipleLoginData.filter(
                        (data) => data.isActive
                    );
                setMultipleLoginData(loginData);
            } else {
                await onSessionCreationSuccess({ user: response.entity });
            }
        } else {
            setMessage(response.message);
            onSessionCreationError();
        }
    }

    const handleInvestorSessionLogin = (investorReferenceId) => {
        userInput.investorReferenceId = investorReferenceId;
        setMultipleMessage('');
        setLoginButtonState(true);
    };

    const onResendOtp = async () => {
        setOtpValue('');
        setIsResendButtonLoading(true);
        const response = await resendOtp({
            context: OtpContext.AUTHENTICATION,
            mobile: mobileNumber,
        });
        if (response.status) {
            setMessage('');
            setOtpMessage('OTP sent successfully!');
            setDisabled(false);
            setSeconds(otpExpiresAfter);
        } else {
            setMessage('Failed to resend the OTP. Try again!');
        }
        setIsResendButtonLoading(false);
    };

    useEffect(() => {
        if (seconds > 0) {
            timeoutRef.current = setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setOtpMessage('');
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [seconds]);

    const phoneInputStyle = {
        containerStyle: {
            borderRadius: '8px',
            borderWidth: '1px',
            height: '48px',
            borderColor: theme?.colors?.gray?.[200],
        },
        inputStyle: {
            width: '100%',
            height: '100%',
            borderWidth: '0px',
            paddingLeft: '3rem',
            borderColor: theme?.colors?.gray?.[200],
            backgroundColor: theme?.colors?.basicWhite,
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '14px',
            color: theme?.colors?.gray?.[900],
            fontFamily: 'Montserrat',
        },
        buttonStyle: {
            borderWidth: '0px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            width: '3rem',
            overflow: 'clip',
            backgroundColor: theme?.colors?.basicWhite,
        },
        dropdownStyle: {
            overflow: 'auto',
            height: '185px',
            width: width <= 480 ? (width <= 374 ? '178px' : '233px') : '300px',
        },
    };

    return (
        <>
            <NextHeadTag {...HeadTagData['#VerifyOtpModal']} />

            {!selectAccountModal ? (
                <Modal
                    show={show && !selectAccountModal}
                    onClose={loading ? null : onClose}
                    width="md:max-w-[440px] max-w-[328px] !m-0"
                    className="px-4 pt-4 !m-0 md:px-6"
                    label="Verify OTP"
                    canCloseOuter={false}
                    labelSize={'!h5-bold !text-primary-500'}
                    labelPadding={'!pb-0 p-4 md:p-6 border-b-0'}
                    children={
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-4">
                                <div className="text-center">
                                    <Text
                                        content={`A ${otpLength} digit OTP has been sent to you at`}
                                        className="mb-2 text-gray-900 p4-medium"
                                    />
                                    <Text
                                        content={appendCountryCodeAndFormatMobile(mobileNumber, countryCode)}
                                        className="inline pr-2 p4-medium text-basicBlack"
                                    />
                                    <span
                                        onClick={editMobile}
                                        className="cursor-pointer"
                                    >
                                        <Image
                                            src="/images/edit.svg"
                                            width="16"
                                            height="17"
                                            className="inline"
                                        />
                                    </span>
                                    {otpModes?.some(element => element.name === 'email') && <Text
                                        content={maskEmail(otpModes.find(element => element.name === 'email').to.toLowerCase())}
                                        className="p4-medium text-basicBlack mt-2"
                                    />}
                                    {userInput?.documentId && (
                                        <Text
                                            content={`For PAN ******${userInput.documentId.substr(
                                                -4
                                            )}`}
                                            className="my-5 text-base text-gray-900"
                                            weight="medium"
                                        />
                                    )}
                                </div>
                                <div className="flex flex-col w-full gap-4 text-center">
                                    <div class="flex justify-center">
                                        {OtpInputComponent}
                                    </div>

                                    <Button
                                        id="verifyOtpButton"
                                        buttonType="primary"
                                        buttonSize='large'
                                        isLoading={loading}
                                        disabled={
                                            otpValue.length !== otpLength || !!message
                                        }
                                        buttonText="Verify"
                                        widthClass={"w-full"}
                                        type='submit'
                                    />

                                    <div className="flex flex-col -mt-2">
                                        {message && (
                                            <Text
                                                content={message}
                                                className="text-center text-semantic-error-base p4-medium"
                                            />
                                        )}
                                        {otpMessage && (
                                            <h1 className="text-center text-semantic-success-base p4-medium">
                                                {otpMessage}
                                            </h1>
                                        )}
                                        <div className='w-full flex justify-center'>
                                            <Button onClick={() =>
                                                seconds === 0 && onResendOtp()}
                                                buttonSize='small'
                                                buttonType='ghost'
                                                buttonText={seconds === 0 ? 'Resend OTP' : `Resend OTP in 00:${seconds < 10
                                                    ? `0${seconds}`
                                                    : `${seconds}`
                                                    } Seconds`}
                                                disabled={seconds !== 0}
                                                type="button"
                                                isLoading={isResendButtonLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                />
            ) : (
                <Modal
                    show={selectAccountModal}
                    width="md:max-w-[440px] max-w-[328px] !m-0"
                    className="px-4 pt-4 md:px-6"
                    labelSize={'!h5-bold !text-primary-500'}
                    labelPadding={'!pb-0 p-4 md:p-6 border-b-0'}
                    canCloseOuter={false}
                    onClose={loading ? null : onClose}
                    label="Login"
                    children={
                        <form onSubmit={handleSubmit(onPanSubmit)}>
                            <label
                                className={`block w-full text-left text-gray-900 p4-medium`}
                            >
                                Mobile Number
                            </label>
                            <div className="text-gray-600 p4-regular">
                                <PhoneInput
                                    value={userInput.mobile}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onFocus={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()}
                                    inputClass={
                                        'px-5 h-full text-gray-900 disabled'
                                    }
                                    disableDropdown={true}
                                    containerClass={'h-12 my-2 flex w-full'}
                                    containerStyle={
                                        phoneInputStyle.containerStyle
                                    }
                                    inputStyle={phoneInputStyle.inputStyle}
                                    buttonStyle={phoneInputStyle.buttonStyle}
                                    inputProps={{ autoFocus: false }}
                                    disabled={true}
                                />
                                You have {multipleInvestor.length} investor
                                records linked to your login ID. Please select
                                the account you wish to access.
                            </div>

                            <div className="flex flex-col w-full gap-4 py-4 overflow-y-auto">
                                {multipleInvestor?.map((investor, index) => (
                                    <div className="pl-1" key={index}>
                                        <div className="text-gray-900 p4-medium ">
                                            <label className="flex items-center ">
                                                <input
                                                    className="mr-2 ring-0"
                                                    type="radio"
                                                    name="pan selection"
                                                    value={investor.name}
                                                    onChange={() =>
                                                        handleInvestorSessionLogin(
                                                            investor?.investorReferenceId
                                                        )
                                                    }
                                                />
                                                {investor.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {multipleMessage && (
                                <Text
                                    content={multipleMessage}
                                    className="pb-3 text-center p5-regular text-semantic-error-base"
                                />
                            )}
                            <Button
                                id="verifyOtpButton"
                                buttonType="primary"
                                buttonSize='large'
                                isLoading={loading}
                                disabled={!loginButtonState}
                                widthClass='w-full'
                                buttonText="Log In"
                            />
                        </form>
                    }
                />
            )}
        </>
    );
};
export default VerifyOtpModal;
