import { Button, Text, Modal, Image } from '@atoms/index';
import { removeReferralEntityType } from '@services/identity.service';

const ConfirmationSignUpModal = ({
    show,
    setShowConfirmationModal,
    message,
    signUp,
    setLoading,
    removeReferralCode,
    userData,
    referralCode
}) => {
    return (
        <Modal
            show={show}
            width="w-[500px] !rounded-xl"
            className="!p-4 md:p-8 z-100"
            onClose={() => setShowConfirmationModal(false)}
            hideTitle={true}
            children={
                <div className="flex  flex-wrap ">
                    <div className="flex flex-col items-center self-stretch justify-center flex-1 w-full md:p-0 md:justify-start md:flex-none">
                        <Image src={'/images/investment/triangle-warning-in-circle.svg'} alt="Warning" className="w-12 h-12 " />
                        <div className="flex flex-col items-center gap-2">
                            <Text
                                content={message}
                                className="text-center text-gray-900 h5-semibold"
                            />
                        </div>
                    </div>
                    {/* <div className="flex flex-row  my-2 md:mx-2 gap-4 w-full">
                        <Image src="/images/ExclamationCircle.svg" />
                        <Text
                            content={`${message}`}
                            className="text-lg md:text-lg text-gray-800 font-bold"
                        />
                    </div>*/}
                    <div className="flex flex-col sm:flex-row justify-center mt-6 gap-3 sm:gap-6 items-center w-full">
                        <Button
                            onClick={(e) => {
                                setLoading(false);
                                setShowConfirmationModal(false);
                            }}
                            buttonType="button"
                            design="standard"
                            type="ghost"
                            className={`flex rounded-lg justify-center  items-center !px-6 !py-3`}
                            icon="yes"
                            children={
                                <>
                                    <Text
                                        content="No, cancel"
                                        className="p4-semibold text-primary-500"
                                    />
                                </>
                            }
                        />
                        <Button
                            onClick={(e) => {
                                setShowConfirmationModal(false);
                                removeReferralCode();
                                removeReferralEntityType();
                                signUp(userData);
                            }}
                            buttonType="button"
                            design="standard"
                            type="primary"
                            className={`flex rounded-lg justify-center  items-center !px-6 !py-3`}
                            icon="yes"
                            children={
                                <Text
                                    content="Yes, continue"
                                    className="p4-semibold text-basicWhite"
                                />
                            }
                        />

                    </div>
                </div>
            }
        />
    );
};

export default ConfirmationSignUpModal;
